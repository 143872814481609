import React, { useEffect } from "react"
import Layout from "../components/layout"

import theme from '../utils/theme'
import bannerD from '../images/aboutbannerD.jpg';
import bannerM from '../images/aboutbannerM.jpg';
import '../styles/about-us.scss';


import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { Button, Grid, Typography, Container } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    componentWrapper: {
        [theme.breakpoints.down('md')]: {
            padding: `${theme.spacing(8)}px  64px`,
        },
        maxWidth: '1240px',
        padding: `0px 40px`
    },
}))
const AboutUs = () => {
    const classes = useStyles()
    return (
        <ThemeProvider theme={theme}>
            < Layout >
                <Container className={classes.componentWrapper}>
                    <div className="aboutHeading">
                        <Typography variant="h4">About Us</Typography>
                        <picture>
                            <source media="(max-width:465px)" srcSet={bannerM} />
                            <img src={bannerD} alt="Flock Along" className="aboutBannerImg" />
                        </picture>
                        <div className="aboutDesc">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At iusto ipsum ullam similique eius, unde odit dignissimos ad vitae, quam excepturi repellendus voluptatum natus quas esse minima laborum, accusantium recusandae?</p>
                            <ul>
                                <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sunt iusto libero alias nihil doloremque necessitatibus quasi mollitia quibusdam.</li>
                                <li>Explicabo, facilis? Accusamus deleniti unde consequatur fuga eum. Dolorum molestias culpa tempore, veritatis deleniti totam ea commodi repellendus?</li>
                                <li>Consequatur, excepturi. Eius nostrum quibusdam aperiam voluptates repudiandae at voluptas, qui iste aliquid. Error voluptatum perspiciatis dolores ab?</li>
                                <li>Unde id asperiores deserunt, tenetur, ipsam modi ut nostrum aperiam eius ipsa veniam autem earum eos sequi minus.</li>
                                <li>Eaque, praesentium tenetur. Labore ipsa sunt, provident deleniti, omnis facere pariatur cupiditate dolore ab debitis ex harum obcaecati.</li>
                                <li>Aut non necessitatibus autem molestias eum nesciunt assumenda, ipsam error corrupti, rerum aliquid voluptatum iusto molestiae, placeat qui?</li>
                                <li>Tenetur, nulla. Recusandae perspiciatis obcaecati amet molestias distinctio quae aperiam ab delectus dolor saepe laudantium explicabo, in totam.</li>
                                <li>Quis ab libero optio cumque quae sed nisi dolor nobis, doloribus eos ad, tempore nihil, quibusdam placeat nulla?</li>
                            </ul>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestiae rerum, dolor ex esse hic cumque consectetur qui eos, eum aspernatur ab nisi porro et, reprehenderit perferendis tenetur eligendi aperiam fuga.</p>
                            <ul>
                                <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet perspiciatis omnis qui quaerat facere?</li>
                                <li>Voluptatibus aliquam eos temporibus qui tempora nisi quis blanditiis exercitationem nam iste. Obcaecati, quaerat?</li>
                                <li>Consequuntur, obcaecati! Odio omnis nihil dolor nobis blanditiis aliquid repellat iusto suscipit rem debitis?</li>
                                <li>Commodi qui eos inventore voluptatem, sed adipisci consequuntur labore, cupiditate minima, aliquam optio. Officia.</li>
                                <li>Eum voluptatibus nostrum, quidem repellendus et fuga nemo, eveniet perferendis alias corrupti, omnis ducimus.</li>
                                <li>Cum doloribus est asperiores vitae quos. Repellendus fuga quam sed tempore reiciendis voluptatibus error?</li>
                            </ul>
                        </div>
                    </div>
                </Container>
            </Layout >
        </ThemeProvider >
    )
}

export default AboutUs
